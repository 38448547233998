.card-2 {
  margin: 0 15rem;
  height: 100%;
}

@media screen and (max-width: 1500px) {
  .card-2 {
    margin: 0 max(96px, calc(50vw - 720px));
  }
}

@media screen and (max-width: 1200px) {
  .card-2 {
    margin: 0 2vw;
  }
}
@media screen and (max-width: 600px) {
  .card-2 {
    margin: 0 calc(50vw - 240px);
  }
}
@media screen and (max-width: 500px) {
  .card-2 {
    margin: 0 2vw;
  }
}
