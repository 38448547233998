@import "sizes";
@mixin font_size($x) {
  line-height: 120%;

  @if ($x== "h1") {
    font-size: 7.6rem;

    @include size1440 {
      font-size: 7.6rem;
    }
    @include size1024 {
      font-size: 6.4rem;
    }
    @include size900 {
      font-size: 4.8rem;
    }
  }
  @if ($x== "h2") {
    font-size: 2rem;

    @include size1440 {
      font-size: 2rem;
    }
    @include size1024 {
      font-size: 2rem;
    }
    @include size900 {
      font-size: 1.6rem;
    }
  }
  @if ($x== "button") {
    font-size: 2.4rem;
    font-weight: 500;
  }

  @if ($x== "h01") {
    font-size: 2.5rem;

    @include size1440 {
      font-size: 2.5rem;
    }
    @include size1100 {
      font-size: 2.2rem;
    }
    @include size900 {
      font-size: 2rem;
    }
    @include size430 {
      font-size: 1.7rem;
    }
  }

  @if ($x== "h02") {
    font-size: 3.2rem;

    @include size1440 {
      font-size: 3.2rem;
    }
    @include size1100 {
      font-size: 2.8rem;
    }
    @include size900 {
      font-size: 2.5rem;
    }
    @include size430 {
      font-size: 2.2rem;
    }
  }
  @if ($x== "h03") {
    font-size: 3.9rem;

    @include size1440 {
      font-size: 3.9rem;
    }
    @include size1100 {
      font-size: 3.6rem;
    }
    @include size900 {
      font-size: 3.2rem;
    }
    @include size430 {
      font-size: 2.8rem;
    }
  }
  @if ($x== "h04") {
    font-size: 4.9rem;

    @include size1440 {
      font-size: 4.9rem;
    }
    @include size1100 {
      font-size: 4.4rem;
    }
    @include size900 {
      font-size: 3.9rem;
    }
    @include size430 {
      font-size: 3.4rem;
    }
  }
  @if ($x== "h05") {
    font-size: 6rem;

    @include size1440 {
      font-size: 6rem;
    }
    @include size1100 {
      font-size: 5.5rem;
    }
    @include size900 {
      font-size: 4.9rem;
    }
    @include size430 {
      font-size: 4.3rem;
    }
  }

  @if ($x== "b01") {
    font-size: 2rem;

    @include size1440 {
      font-size: 2rem;
    }
    @include size1100 {
      font-size: 1.8rem;
    }
    @include size900 {
      font-size: 1.6rem;
    }
    @include size430 {
      font-size: 1.4rem;
    }
  }
  @if ($x== "b02") {
    font-size: 1.6rem;

    @include size1440 {
      font-size: 1.6rem;
    }
    @include size1100 {
      font-size: 1.4rem;
    }
    @include size900 {
      font-size: 1.2rem;
    }
    @include size430 {
      font-size: 1.1rem;
    }
  }
  @if ($x== "b03") {
    font-size: 1.2rem;

    @include size1440 {
      font-size: 1.2rem;
    }
    @include size1100 {
      font-size: 1.2rem;
    }
    @include size900 {
      font-size: 1.1rem;
    }
    @include size430 {
      font-size: 0.9rem;
    }
  }
  @if ($x== "b04") {
    font-size: 1.1rem;

    @include size1440 {
      font-size: 1.1rem;
    }
    @include size1100 {
      font-size: 1rem;
    }
    @include size900 {
      font-size: 0.9rem;
    }
    @include size430 {
      font-size: 0.7rem;
    }
  }
  @if ($x== "b05") {
    font-size: 0.9rem;

    @include size1440 {
      font-size: 0.9rem;
    }
    @include size1100 {
      font-size: 0.8rem;
    }
    @include size900 {
      font-size: 0.7rem;
    }
    @include size430 {
      font-size: 0.6rem;
    }
  }
}
