@import "fontSize";

@mixin leftGreenTitle {
  color: #4385ce;
  position: relative;
  padding-left: 2.9rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-size: 2rem;

  @include size1800 {
    font-size: 1.7rem;
  }

  @include size1600 {
    font-size: 1.6rem;
  }

  @include size1300 {
    font-size: 1.4rem;
  }

  &::before {
    content: "";
    position: absolute;
    background-color: #4385ce;
    height: 0.2rem;
    width: 2rem;
    top: 50%;
    left: 0;
    border-radius: 1rem;
    transform: translateY(-50%);
  }
}
@mixin center {
  display: grid;
  place-items: center;
}
@mixin mainTitle {
  color: #0e1952;
  padding-top: 1rem;
  // font-family: "Montserrat";
  font-size: 5rem;
  font-weight: 700;
  line-height: 120%;

  @include size1800 {
    font-size: 4.5rem;
  }

  @include size1600 {
    font-size: 3.5rem;
  }
  @include size1400 {
    font-size: 3rem;
  }
  @include size1300 {
    font-size: 2.7rem;
  }
}
@mixin learnMoreButton {
  button {
    display: flex;
    align-items: center;
    margin-top: 5rem;
    background-color: #fff;
    border: none;
    outline: none;
    color: #0d6ae0;
    .text {
      font-size: 1.5rem;
      font-weight: 800;
      padding-right: 1rem;
    }
    img {
      width: 2rem;
      height: 2rem;
    }
  }
}
@mixin setSameWidthHeight($size) {
  width: $size;
  height: $size;
}

@mixin button-styles() {
  // .#{$buttonClassName} {
  background: var(--valleyblue-1, #09f);
  color: #fff;
  outline: none;
  border: none;
  @include font_size(button);
  @include font_size(h01);
  padding: 1.4rem 1.5rem;
  border-radius: 0.8rem;

  text-decoration: none;
  @include size1500 {
    // padding: 2rem 3rem;
    // margin: 4rem 0 0 2rem;
  }
  @include size1000 {
    // padding: 1.5rem 3rem;
    // margin: 4rem 0 0 2rem;
  }
  @include size900 {
    // font-size: 1.6rem;
  }
  @include size600 {
    padding: 1rem 1.5rem;
  }
  // }
}
