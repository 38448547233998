@import "./utilitiesMixins";
.homeImage-container {
  position: relative;
  width: min(30rem, 100%);
  height: fit-content;
  .bgImg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .contentContanier {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20%;
    padding-top: 10%;

    .title,
    .content {
      height: 2lh;
      width: 60%;
      margin-inline: auto;
      @include center;
      text-align: center;
    }
    .centerImg {
      width: 25%;
      position: relative;
      .whitebg {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      .vctContainer {
        position: absolute;
        width: 100%;
        height: 90%;
        top: 0;
        left: 0;
        @include center;
        .icon {
          object-fit: cover;
          width: 50%;
          height: auto;
        }
      }
    }
    container-type: size;
    @container (min-width:10px) {
      .title {
        font-size: 7cqw;
      }
      .content {
        font-size: 6cqw;
      }
    }
  }
}
