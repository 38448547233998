@import "sizes";
@import "utilitiesMixins";
@import "fontSize";

// @mixin leftGreenTitle {
//   color: #4385ce;
//   position: relative;
//   padding-left: 2.9rem;
//   font-weight: 600;
//   letter-spacing: 0.1rem;
//   font-size: 2rem;

//   @include size1800 {
//     font-size: 1.7rem;
//   }

//   @include size1600 {
//     font-size: 1.6rem;
//   }

//   @include size1300 {
//     font-size: 1.4rem;
//   }

//   &::before {
//     content: "";
//     position: absolute;
//     background-color: #4385ce;
//     height: 0.2rem;
//     width: 2rem;
//     top: 50%;
//     left: 0;
//     border-radius: 1rem;
//     transform: translateY(-50%);
//   }
// }

// @mixin mainTitle {
//   color: #0e1952;
//   padding-top: 1rem;
//   // font-family: "Montserrat";
//   font-size: 5rem;
//   font-weight: 700;
//   line-height: 120%;

//   @include size1800 {
//     font-size: 4.5rem;
//   }

//   @include size1600 {
//     font-size: 3.5rem;
//   }
//   @include size1400 {
//     font-size: 3rem;
//   }
//   @include size1300 {
//     font-size: 2.7rem;
//   }
// }

// @mixin learnMoreButton {
//   button {
//     display: flex;
//     align-items: center;
//     margin-top: 5rem;
//     background-color: #fff;
//     border: none;
//     outline: none;
//     color: #0d6ae0;
//     .text {
//       font-size: 1.5rem;
//       font-weight: 800;
//       padding-right: 1rem;
//     }
//     img {
//       width: 2rem;
//       height: 2rem;
//     }
//   }
// }
// @mixin setSameWidthHeight($size) {
//   width: $size;
//   height: $size;
// }
.contactUs {
  position: relative;
  background: #f9f9f9;
  .section1 {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-column-gap: 2rem;
    padding-top: 8rem;
    padding-bottom: 5rem;
    @include size1400 {
      padding-top: 7rem;
    }
    @include size1300 {
      padding-top: 6rem;
    }
    @include size1200 {
      padding-top: 5rem;
    }
    @include size1100 {
      padding-top: 4rem;
    }
    @include size1000 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .section1-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .section1-title {
        @include font_size(h05);
        line-height: 120%;
        font-weight: 600;
        color: var(--valleyblue-2, #0e1952);
      }
      .section1-para {
        padding-top: 2rem;
        @include font_size(b01);
        color: var(--valleygray, #7a7a7a);
        font-family: Outfit;
        font-weight: 400;
        line-height: 120%;
      }
      .section1-button {
        padding-top: 4rem;
        @include size600 {
          padding-top: 4rem;
        }
        .realButton {
          @include button-styles();
        }
      }
    }

    .section1-right {
      width: 100%;
      .internal-container {
        width: 100%;
        position: relative;
        @include size1000 {
          width: 60%;
          margin: 5rem auto;
        }
        @include size600 {
          width: 85%;
        }
        .maindoctor {
          width: 100%;
          animation: fade 1500ms ease-in-out normal forwards;

          > img {
            width: 100%;
          }
        }
        .object {
          border-radius: 10px;
          background: var(--valleywhite, #fff);
          box-shadow: 2px 2px 4px 0px rgba(195, 195, 195, 0.25),
            -2px -2px 4px 0px rgba(208, 208, 208, 0.25);
          position: absolute;
          animation: show-contact 1000ms ease-in-out 200ms normal forwards;

          &.object1 {
            width: 59.2%;
            height: 10%;
            display: flex;

            justify-content: center;
            align-items: center;
            bottom: 43.5%;
            // right: 34%;
            left: 8%;

            container-type: size;
            @container (min-height: 10px) {
              .object1-container {
                height: 70%;
              }
            }
            .object1-container {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.5rem;
              container-type: size;

              .object-title {
                color: var(--valleyblack, #222);

                font-family: Outfit;
                font-weight: 400;
              }
              @container (min-width: 0px) {
                .object-title {
                  font-size: 50cqh;
                }

                .tick {
                  height: 90cqh;
                  .icon {
                    height: 100%;
                  }
                }
              }
            }
          }

          &.object2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 54%;
            height: 17.5%;
            gap: 1rem;
            top: 80%;
            left: 46%;
            container-type: size;
            @container (min-width: 0px) {
              .object2-title,
              .object-content {
                height: 25%;
              }
            }
            .object2-title {
              width: 100%;
              display: flex;
              justify-content: center;

              container-type: normal;
              @container (min-width: 0px) {
                font-size: 7.5cqw;
              }
            }

            .object-content {
              width: 88%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 0.5rem;
              container-type: size;
              .phone {
                color: var(--valleygray, #7a7a7a);
                font-family: Outfit;
                font-weight: 400;
              }
              @container (min-width: 0px) {
                .phone {
                  font-size: 80cqh;
                }

                .contact-icon {
                  height: 115cqh;
                  > img {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .section3 {
    // background-color: #0099ff;
    // position: relative;
    // top: -0.5rem;
    width: 100%;

    padding-bottom: 4.2rem;
    .Bgsection5 {
      width: inherit;
    }
    .section3-internal {
      background-color: #0099ff;
      position: relative;
      top: -0.5rem;
      padding-bottom: 6rem;
      .section3-title {
        color: var(--valleywhite, #fff);
        text-align: center;
        @include font_size(h05);
        font-family: Outfit;
        font-weight: 600;
        line-height: 120%;
        padding-bottom: 3rem;
      }
      .section3-para {
        color: var(--valleywhite, #fff);
        text-align: center;
        @include font_size(b01);
        font-family: Outfit;
        font-weight: 400;
        line-height: 120%;
        padding-bottom: 5rem;
      }
      .section3-bottom-container {
        width: 100%;
        height: fit-content;
        border-radius: 24px;
        background: var(--valleywhite, #fff);
        display: grid;
        grid-template-columns: repeat(2, 50%);
        justify-content: center;
        align-items: center;
        padding: 5rem 0;
        @include size600 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .section3-left-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          gap: 5rem;
          @include size1000 {
            gap: 7rem;
          }
          .contact-doctor {
            width: 80%;

            > img {
              width: 100%;
            }
          }
          .details-container {
            // width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 3rem;
            padding-left: 2rem;
            @include size600 {
            }
            // align-items: center;
            .contact-container {
              display: flex;
              justify-content: flex-start;
              gap: 2rem;

              .icon {
                // width: 100%;
                > img {
                  // width: 100%;
                }
              }
              // align-items: center;
              .content {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                @include size1300 {
                  gap: 1rem;
                }
                .title {
                  color: var(--valleyblack, #222);
                  @include font_size(h01);
                  font-family: Outfit;
                  font-weight: 500;
                  line-height: 120%;
                }
                .number {
                  color: var(--valleygray, #7a7a7a);
                  @include font_size(b01);
                  font-family: Outfit;
                  font-weight: 400;
                  line-height: 120%;
                  // width: 45rem;
                }
              }
            }
          }
        }

        .section3-right-container {
          width: 100%;
          height: 100%;
          @include size600 {
            padding-top: 4rem;
          }
          .submitButtonContainer {
            padding-top: 3rem;
            margin: 1rem 1rem 0;
            @include size1000 {
              padding-top: 2rem;
              margin-left: 2rem;
            }
            .submitButton {
              @include button-styles();
            }
          }
        }
      }
    }
  }

  .section4 {
    margin-top: 5rem;
    padding-bottom: 5rem;
    @include size500 {
      // padding-bottom: 10rem;
    }
    .section4-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        @include font_size(h04);
        width: max-content;
        margin-bottom: 4rem;
        color: var(--valleyblue-2, #0e1952);
        text-align: center;
        font-family: Outfit;
        font-weight: 600;
        line-height: 120%;
        @include size500 {
          // font-size: 3rem;
        }
      }

      .map {
        width: 100%;
        height: 60rem;
        .map-container {
          width: 100%;
          height: 100%;
        }
        @include size500 {
          height: 30rem;
        }
      }
    }
  }
}
@keyframes show-contact {
  from {
    width: 70%;
    opacity: 0;
  }
}
@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
