.card-1 {
  margin: 0 12rem;
  height: 100%;
}

.card-1.w100p {
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .card-1 {
    margin: 0 15rem;
  }
}
@media screen and (max-width: 1200px) {
  .card-1 {
    margin: 0 12rem;
  }
}
@media screen and (max-width: 1000px) {
  .card-1 {
    width: 100%;

    margin: 0;
  }
}
@media screen and (max-width: 500px) {
  .card-1 {
    margin: 0 2rem;
  }
}
