@import "sizes";
@import "fontSize";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat:wght@700&family=Outfit:wght@400;600&display=swap");

.services {
  position: relative;
  background-color: #f9f9f9;

  .section1 {
    display: grid;
    grid-template-columns: 9fr 10fr;
    // align-items: center;
    // justify-content: center;
    // position:relative;
    // overflow: hidden;
    // height:72rem;
    // @include size1000{
    //   grid-template-columns: 6fr 4fr;
    // }
    @include size1200 {
      grid-template-columns: 7fr 7fr;
    }
    @include size1100 {
      grid-template-columns: 7.5fr 9fr;
    }
    @include size1000 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 4rem;
    }
    @include size900 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    // @include size800 {
    //   grid-template-columns: 14fr 7fr;
    // }
    // @include size800 {
    //   grid-template-columns: 16fr 6fr;
    // }
    // @include size700 {
    //   grid-template-columns: 18fr 6fr;
    // }
    // @include size600 {
    //   display: flex;
    //   flex-direction: column;
    // }

    // padding-top: 8rem;
    .content {
      width: 100%;
      height: 100%;
      // margin-top: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include size1100 {
        margin-left: 2rem;
      }
      @include size900 {
        margin-top: 4rem;
        margin-left: 5rem;
        margin-right: 5rem;
      }
      // padding-left:2rem;

      // @include size1000{
      //   display:flex;
      //   flex-direction:column;
      //   align-items: flex-start;
      // }

      .section1-heading {
        font-size: 7.6rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        font-family: Outfit;
        color: #0e1952;
        @include font_size(h1);
        // @include size1100 {
        //   font-size: 6.2rem;
        // }
        @include size900 {
          // font-size: 4.8rem;
          // font-style: normal;
          // font-weight: 500;
          // line-height: normal;
          width: 40rem;
        }
        @include size600 {
          // font-size:55px;
          // line-height: 1;
        }
      }
      .section1-para {
        @include font_size(h2);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: Outfit;
        margin-top: 3rem;
        color: #7a7a7a;
        // @include size1200 {
        //   font-size: calc(1.52rem + 0.4vw);
        // }
        // @include size900 {
        //   font-size: 1.6rem;
        // }
        @include size600 {
          margin-top: 2rem;
        }
      }
      .section1-button {
        // position:absolute;
        // top:18.5%;
        padding-top: 5rem;
        .realButton {
          background: var(--valleyblue-1, #09f);
          color: #fff;
          outline: none;
          border: none;
          font-size: 2.4rem;
          font-family: Outfit;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 1.5rem;
          border-radius: 0.8rem;
          // margin: 4rem 0 0 2rem;
          text-decoration: none;

          @include size900 {
            font-size: 1.6rem;
            // padding: 1.5re;
            // border-radius: 0.8rem;
          }
          @include size600 {
            padding: 1rem;
          }
        }
        @include size600 {
          padding-top: 3rem;
        }
      }
    }
    .doctor-image {
      // position:absolute;
      // background-color: #f00;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-top: 3rem;
      animation: fade 1500ms ease-in-out normal forwards;
      position: relative;
      @include size1000 {
        justify-content: center;
        padding-top: 5rem;
      }

      // @include size1000 {
      //   width: 110%;
      // }
      // @include size900 {
      //   // width: 150%;
      //   // margin-left: -10%;
      //   margin-top: 2rem;
      // }
      // @include size800 {
      //   margin-left: -45%;
      //   top: -2rem;
      //   width: 160%;
      // }
      // @include size800 {
      //   width: 220%;
      // }
      // @include size600 {
      //   width: 100%;
      //   margin-left: 0;
      //   padding-top: 6rem;
      // }
      .internalContainer {
        position: relative;
        width: 100%;
        @include size1100 {
          // width: 50vw;
          width: 190%;
          // transform: translateX(-20%);
        }
        @include size1000 {
          width: 60%;
          // transform: translateX(-18%);
        }
        @include size900 {
          width: 60%;
          // transform: translateX(-24%);
        }
        @include size800 {
        }
        @include size700 {
        }
        @include size600 {
          width: 100%;
        }

        > img {
          width: 100%;
          height: auto;
          object-fit: cover;
          animation: fade 1500ms ease-in-out normal forwards;
        }
        .regular-checkup {
          padding: 0.7vw;
          display: flex;
          align-items: center;
          // animation: show 1000ms ease-in-out 200ms normal forwards;
          position: absolute;
          bottom: 65%;
          right: 55%;
          background: #ffffff;
          box-shadow: -2px -2px 10px rgba(218, 218, 218, 0.25),
            2px 2px 10px rgba(214, 214, 214, 0.25);
          border-radius: 8px;
          img {
            width: calc(1.3rem + 2vw);
          }
          p {
            font-family: "Outfit";
            font-style: normal;
            font-weight: 400;
            font-size: calc(1rem + 1vw);
            white-space: nowrap;
            line-height: 30px;
            padding-left: 0.5vw;
            color: #222222;
            @include size600 {
              font-size: 16px;
            }
          }
          @include size900 {
            padding: 10px;
          }
          @include size800 {
            padding: 5px;
          }
        }
        .doctoDesignElement {
          position: absolute;
          bottom: 85%;
          right: 56%;
          // animation: show-right 1000ms ease-in-out 200ms normal forwards;
          // background: #ffffff;

          // @include size1100 {
          //   top: 3%;
          // }
          @include size600 {
            width: 5rem;
            height: 5rem;
            bottom: 77%;
            right: 56%;
          }
          .redheart {
            position: absolute;
            width: 8rem;
            height: auto;
            aspect-ratio: 1;
            bottom: 100%;
            right: 100%;
            animation: show-right 1000ms ease-in-out 200ms normal forwards;

            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            box-shadow: -2px -2px 8px rgba(255, 183, 183, 0.25),
              2px 2px 8px rgba(255, 202, 202, 0.25);
            border-radius: 50%;
            transform: translate(-5%, 10%);
            img {
              width: 50%;
              height: auto;
              object-fit: cover;
              @include size800 {
                // width: 30%;
              }
            }
            @include size1200 {
              width: calc(6.8rem + 1vw);
            }
            @include size800 {
              width: 5rem;
            }
          }
        }
        .circle {
          width: 3rem;
          height: auto;
          aspect-ratio: 1;
          background: #ffffff;
          box-shadow: -2px -2px 8px rgba(255, 183, 183, 0.25),
            2px 2px 8px rgba(255, 202, 202, 0.25);
          border-radius: 50%;
          // position: absolute;
          // bottom: 85%;
          // right: 55%;
          @include size1200 {
            width: calc(1.8rem + 1vw);
          }
          @include size800 {
            width: 2rem;
          }
          // @include size1100 {
          //   top: 15%;
          //   left: 42%;
          // }
          // @include size600 {
          //   width: 2.5rem;
          //   height: 2.5rem;
          //   left: 36%;
          //   top: 18%;
          // }
        }
        .object-container {
          background: #ffffff;
          box-shadow: -2px -2px 10px rgba(218, 218, 218, 0.25),
            2px 2px 10px rgba(219, 219, 219, 0.25);
          border-radius: 8px;
          // width:30rem;
          // height:14rem;
          padding: 0.7vw;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          animation: show-object 1000ms ease-in-out 200ms normal forwards;
          position: absolute;
          bottom: 10%;
          right: 10%;
          @include size1200 {
            right: 15%;
            // bottom:2%;
          }
          @include size1100 {
            right: 17.5%;
            // bottom:2%;
          }
          @include size1000 {
            bottom: 8%;
            right: 16%;
            // bottom:2%;
          }
          @include size900 {
            padding: 12px;
            right: 11%;
            bottom: 10%;
          }
          @include size800 {
            right: 6%;
            padding: 5px;
            bottom: 5%;
          }
          // @include size700 {
          //   right: 40%;
          // }

          .object-heading {
            font-family: "Outfit";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 120%;
            padding-bottom: 1rem;
            padding-left: 0.5rem;

            color: #222222;
            @include size1200 {
              font-size: calc(1.2rem + 1vw);
            }
            @include size600 {
              //  font-size: 16px;
            }
          }

          .image-container {
            display: flex;
            justify-content: flex-start;
            img {
              margin: 0 0.5rem;
              width: 5rem;
              height: auto;
              aspect-ratio: 1;

              @include size1200 {
                width: calc(2.6rem + 2vw);
              }
              // @include size700 {
              //   &:nth-child(3) {
              //     display: none;
              //   }
              // }
            }
          }
        }
      }
    }
  }
  .section2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    grid-column-gap: 5rem;

    @include size1000 {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 0;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .section2-heading {
        font-family: Outfit;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @include font_size(h1);

        /* VALLEY BLUE2 */
        color: #0e1952;
        margin-bottom: 3rem;
      }
      .section2-para {
        @include font_size(h2);
        font-family: Outfit;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        /* RLH GRAY */

        color: #7a7a7a;
      }
      .curve3 {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        height: 10rem;
        img {
          display: block;
          height: 120%;
          width: auto;
          object-fit: cover;
          @include size600 {
            height: 80%;
          }
        }
      }
    }
    .section2-image-container {
      width: 100%;
      height: auto;
      aspect-ratio: 624/723;
      position: relative;
      @include size1000 {
        width: 80%;
        margin-inline: auto;
        // margin-top: 2rem;
      }
      @include size600 {
        width: 100%;
      }
      // background-color: #f00;

      .img1 {
        width: 40.38%;
        height: auto;
        aspect-ratio: 252/221;
        position: absolute;
        right: 3.68%;
        z-index: 2;
        bottom: 49.91%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .img2 {
        width: 54.64%;
        height: auto;
        aspect-ratio: 341/221;
        position: absolute;
        top: 54.51%;
        right: 3.6%;

        z-index: 2;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .img3 {
        width: 41.02%;
        height: auto;
        aspect-ratio: 256/239;
        position: absolute;
        top: 34.58%;
        left: 2.2%;
        z-index: 2;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .img4 {
        width: 54.48%;
        height: auto;
        aspect-ratio: 340/324;
        position: absolute;
        right: 25.48%;
        top: 24.53%;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .curve1 {
        width: 16.5%;
        height: auto;
        aspect-ratio: 103/106;
        position: absolute;
        top: 4.7%;
        left: 18.42%;
        transform-origin: left center;
        overflow: hidden;
        &[data-aos="curve1-animation"] {
          width: 0;
          transition-property: width;

          &.aos-animate {
            width: 10.1rem;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .curve4 {
        width: 13.46%;
        height: auto;
        aspect-ratio: 84/87;
        position: absolute;
        bottom: 10%;
        left: 11.69%;
        &[data-aos="curve1-animation"] {
          width: 0;
          transition-property: width;

          &.aos-animate {
            width: 8.2rem;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .section3 {
    width: 100%;
    position: relative;
    padding-top: 10rem;
    padding-bottom: 5rem;
    @include size600 {
      padding-top: 10rem;
    }

    .section3-title {
      font-family: "Outfit";
      font-style: normal;
      font-weight: 500;
      @include font_size(h1);
      text-align: center;
      color: #0e1952;
      @include size1200 {
        padding-bottom: 2rem;
      }
      @include size1000 {
        padding-bottom: 2rem;
      }
    }
    .blueray {
      // position: absolute;
      // left: 80%;
      // bottom: 10%;
      // height: 6rem;
      // img {
      //   display: block;
      //   height: 120%;
      //   width: auto;
      //   object-fit: cover;
      //   @include size600 {
      //     height: 80%;
      //   }
      // }
      position: absolute;

      right: 6%;
      top: -3rem;

      img {
        @include size1100 {
          width: 80%;
        }
        @include size1000 {
          width: 65%;
        }
        @include size900 {
          width: 55%;
        }
        @include size800 {
          // width: 75%;
        }
        @include size700 {
          // width: 70%;
        }
        @include size600 {
          width: 50%;
        }
      }
      @include size1100 {
        top: -7rem;
        right: 3%;
        padding-top: 4rem;
      }
      @include size1000 {
        top: -3rem;
      }
      @include size900 {
        right: -9%;
        top: -2rem;
      }
      @include size700 {
        right: -12%;
      }
      @include size600 {
        right: -22%;
        top: -1rem;
      }
      // right:0%;
    }

    .carousel-container {
      width: 100%;
    }

    .section3-title {
      font-family: "Outfit";
      font-style: normal;
      font-weight: 500;
      @include font_size(h1);
      text-align: center;
      color: #0e1952;
      @include size1200 {
        padding-bottom: 2rem;
      }
      @include size1000 {
        padding-bottom: 2rem;
      }
    }
  }
  .section4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // gap:2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    // padding-top: 5rem;
    @include size1100 {
      // padding-top: 2rem;
    }
    @include size1000 {
      display: flex;
      flex-direction: column;
      padding-top: 2rem;
    }
    @include size900 {
      grid-template-columns: 3fr 2fr;
    }
    @include size800 {
      grid-template-columns: 5fr 3fr;
    }
    @include size700 {
      grid-template-columns: 9fr 4fr;
      //  padding-top: 6rem;
    }
    @include size600 {
      grid-template-columns: 15fr 4fr;
    }
    @include size600 {
      display: flex;
      flex-direction: column;
      padding-top: 8rem;
    }

    .left-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .blueheart {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        height: 5rem;
        > img {
          display: block;
          height: 120%;
          width: auto;
          object-fit: cover;
          @include size600 {
            height: 80%;
          }
        }
      }
      .section4-heading {
        font-family: "Outfit";
        font-style: normal;
        font-weight: 500;
        @include font_size(h1);
        line-height: 120%;
        color: #0e1952;
        padding-bottom: 3rem;
        // @include size1000 {
        //   width: 60rem;
        // }
        // @include size600 {
        //   width: 55rem;
        // }
        // @include size500 {
        //   width: 38rem;
        // }
      }
      .section4-para {
        font-family: "Outfit";
        font-style: normal;
        font-weight: 400;
        @include font_size(h2);
        line-height: 25px;
        color: #7a7a7a;
      }
    }

    .right-content {
      width: 100%;
      animation: fade 3000ms ease-in-out normal forwards;
      @include size1000 {
        display: flex;
        justify-content: center;
      }

      @include size900 {
        // margin-left: -2rem;
      }

      // padding-right:8rem;
      img {
        width: 105%;
        @include size1000 {
          width: 60%;
        }
        @include size900 {
          // width: 120%;
        }
        @include size800 {
          // width: 140%;
        }
        @include size700 {
          // width: 200%;
        }

        @include size600 {
          width: 100%;
        }
      }
    }
  }
}
// @keyframes fade {
//   from {
//     opacity: 0.2;
//   }
//   to {
//     opacity: 1;
//   }
// }

@keyframes show {
  from {
    width: 10%;
    opacity: 0;
  }
}
