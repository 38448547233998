@import "sizes";
@import "utilitiesMixins";

.footer {
  .footer-top {
    width: 100%;
    img {
      width: inherit;
    }
  }
  // .footer-middle {

  // }
  .footer-container {
    // display: grid;
    // grid-template-columns: 7fr 5fr;
    // grid-column-gap: 6rem;
    // justify-content: space-between;
    // align-items: center;
    background-color: #0099ff;

    position: relative;
    top: -0.5rem;
    width: 100%;

    padding-bottom: 4.2rem;
    .footer-middle {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 7fr 5fr;
      grid-column-gap: 6rem;
      justify-content: space-between;
      align-items: center;
      @include size1000 {
        grid-column-gap: 3rem;
      }
      @include size800 {
        grid-column-gap: 0rem;
      }
      @include size600 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .content1-container {
        display: grid;
        grid-template-columns: repeat(2, 3.5fr);
        grid-column-gap: 6rem;
        justify-content: center;
        align-items: center;
        height: 100%;
        @include size800 {
          grid-column-gap: 0rem;
        }
        // @include size700 {
        //   grid-column-gap: 4rem;
        // }

        .content1 {
          display: flex;
          height: 70%;

          flex-direction: column;
          justify-content: space-between;
          @include size600 {
            height: 110%;
          }

          .content1-heading {
            color: #fff;
            font-family: Outfit;
            font-weight: 500;
            line-height: 120%;
            @include font_size(h01);
          }
          .content1-para {
            @include font_size(b01);
            color: #fff;
            font-family: Outfit;
            font-weight: 400;
            line-height: normal;
            display: flex;
            justify-content: flex-start;
          }
        }

        .content2 {
          display: flex;
          height: 70%;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          @include size600 {
            height: 110%;
          }

          .content2-part1 {
            display: flex;
            flex-direction: column;

            .content2-heading {
              color: #fff;
              font-family: Outfit;
              font-weight: 500;
              line-height: 120%;
              @include font_size(h01);
              padding-bottom: 2rem;
              @include size600 {
                padding-bottom: 1rem;
              }
            }
            .content2-content {
              display: flex;
              gap: 1rem;
              @include size600 {
                gap: 0.5rem;
              }
              .icon {
                width: 10%;
                img {
                  width: 100%;
                }
              }

              .content2-details {
                @include font_size(b01);
                color: #fff;
                font-family: Outfit;
                font-weight: 400;
                line-height: 120%;
                width: 25rem;
                display: flex;
                align-items: center;
                @include size900 {
                  width: 20rem;
                }
                @include size700 {
                  width: 15rem;
                }
                @include size700 {
                  width: 16rem;
                }
                @include size500 {
                  width: 14rem;
                }
              }
            }
          }
        }
      }

      .content3 {
        width: 100%;
        height: 35rem;
        @include size600 {
          height: 25rem;
          width: 90%;
          padding-top: 4rem;
        }
        .internal-container {
          width: 100%;
          height: 100%;
          // object-fit: cover;

          .map-container {
            width: 100%;
            height: 100%;
          }
          // @include size800 {
          //   width: 110%;
          // }
          // @include size700 {
          //   width: 115%;
          //   transform: translateX(-5%);
          // }
          // @include size600 {
          //   width: 80%;
          //   margin-inline: auto;
          //   padding-top: 3rem;
          // }
          > img {
            width: 100%;
          }
        }
      }
    }
  }
  .bottom-container {
    background: var(--valleyblue-2, #0e1952);
    padding: 2rem 0;
    .footer-bottom {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 5rem;
      .left {
        display: flex;
        gap: 1rem;
        @include size900 {
          gap: 0.5rem;
        }
        .left-icon {
          @include size900 {
            width: 10%;
          }
          > img {
            width: 100%;
          }
        }
        .left-heading {
          color: #fff;
          @include font_size(b01);
          font-family: Outfit;
          font-weight: 400;
          line-height: 120%;
          display: flex;
          align-items: center;
        }
      }
      .right {
        color: #fff;
        @include font_size(b01);
        font-family: Outfit;
        font-weight: 400;
        line-height: 120%;
      }
    }
  }

  // .footer-container {
  //   .footer-designElement1 {
  //     position: absolute;
  //     width: 100rem;
  //     height: 100rem;
  //     border: 12rem solid rgba(#fff, 0.02);
  //     top: 0;
  //     right: 0;
  //     z-index: 10;
  //     border-radius: 50%;
  //     transform: translate(0, -50%);

  //     @include size1800 {
  //       width: 93rem;
  //       height: 93rem;
  //       border: 11rem solid rgba(#fff, 0.02);
  //     }
  //     @include size1700 {
  //       width: 85rem;
  //       height: 85rem;
  //       border: 10rem solid rgba(#fff, 0.02);
  //     }
  //   }
  //   .footer-designElement2 {
  //     position: absolute;
  //     // background-color: #fff;
  //     width: 65rem;
  //     height: 65rem;
  //     border: 0.1rem solid rgba(#fff, 0.5);
  //     top: 50%;
  //     left: 0;
  //     z-index: 10;
  //     border-radius: 50%;
  //     transform: translate(-75%, -50%);

  //     @include size1800 {
  //       width: 60rem;
  //       height: 60rem;
  //     }
  //     @include size1700 {
  //       width: 55rem;
  //       height: 55rem;
  //     }
  //   }
  //   .part1 {
  //     position: relative;
  //     background-color: #0e63dc;
  //     width: 100%;
  //     height: 36rem;
  //     transform: translateY(-50%);
  //     border-radius: 0 0 0 18rem;
  //     overflow: hidden;

  //     @include size1800 {
  //       height: 34rem;
  //       border-radius: 0 0 0 17rem;
  //     }
  //     @include size1700 {
  //       height: 30rem;
  //       border-radius: 0 0 0 15rem;
  //     }
  //     @include size1700 {
  //       height: 20rem;
  //       border-radius: 0 0 0 5rem;
  //       padding: 0 1.5rem;
  //     }
  //     .footer-designElement4 {
  //       position: absolute;
  //       @include setSameWidthHeight(20rem);
  //       border: 0.1rem solid rgba(#fff, 0.35);
  //       top: 0;
  //       left: 0;
  //       z-index: -1;
  //       border-radius: 50%;
  //       transform: translate(-50%, -50%);

  //       @include size1800 {
  //         @include setSameWidthHeight(19rem);
  //       }
  //       @include size1700 {
  //         @include setSameWidthHeight(18rem);
  //       }
  //       @include size1600 {
  //         @include setSameWidthHeight(17rem);
  //       }
  //       @include size1500 {
  //         @include setSameWidthHeight(16rem);
  //       }
  //       @include size1400 {
  //         @include setSameWidthHeight(15rem);
  //       }
  //       @include size1300 {
  //         @include setSameWidthHeight(14rem);
  //       }
  //       @include size1200 {
  //         @include setSameWidthHeight(13rem);
  //       }
  //       @include size1100 {
  //         @include setSameWidthHeight(12rem);
  //       }
  //     }
  //     .footer-designElement5 {
  //       position: absolute;
  //       @include setSameWidthHeight(63rem);
  //       border: 7rem solid #0e5ed2;
  //       top: 0;
  //       left: 0;
  //       z-index: -1;
  //       border-radius: 50%;
  //       transform: translate(-50%, -50%);
  //       @include size1800 {
  //         @include setSameWidthHeight(60rem);
  //       }
  //       @include size1700 {
  //         @include setSameWidthHeight(57rem);
  //       }
  //       @include size1600 {
  //         @include setSameWidthHeight(53rem);
  //       }
  //       @include size1500 {
  //         @include setSameWidthHeight(50rem);
  //       }
  //     }
  //     .footer-designElement6 {
  //       position: absolute;
  //       @include setSameWidthHeight(80rem);
  //       border: 10rem solid #0e5fd5;
  //       top: 0;
  //       right: 0;
  //       z-index: -1;
  //       border-radius: 50%;
  //       transform: translate(-30%, -70%);

  //       @include size1800 {
  //         @include setSameWidthHeight(78rem);
  //       }
  //       @include size1700 {
  //         @include setSameWidthHeight(75rem);
  //       }
  //       @include size1600 {
  //         @include setSameWidthHeight(71rem);
  //       }
  //     }
  //     .footer-designElement7 {
  //       position: absolute;
  //       width: 29rem;
  //       height: 29rem;
  //       border: 0.1rem solid rgba(#fff, 0.5);
  //       top: 50%;
  //       right: 0;
  //       z-index: -1;
  //       border-radius: 50%;
  //       transform: translate(50%, -50%);
  //     }

  //     .part1-container {
  //       margin: 3rem 14rem;
  //       display: flex;
  //       height: calc(100% - 4rem);

  //       @include size1800 {
  //         margin: 2.5rem 12rem;
  //       }
  //       @include size1700 {
  //         margin: 2rem 10rem;
  //       }
  //       @include size500 {
  //         flex-direction: column;
  //         margin: 0rem 0rem;
  //       }

  //       .part1-container_left {
  //         width: 60%;
  //         height: 100%;
  //         @include size500 {
  //           width: 100%;
  //         }

  //         .title {
  //           font-size: 5rem;
  //           font-weight: 600;
  //           color: #fff;
  //           margin-top: 5rem;
  //           margin-bottom: 4rem;

  //           @include size1800 {
  //             font-size: 5.5rem;
  //             margin-top: 4.5rem;
  //             margin-bottom: 3rem;
  //           }
  //           @include size1700 {
  //             font-size: 4.5rem;
  //             margin-top: 4rem;
  //             margin-bottom: 2rem;
  //           }
  //           @include size1600 {
  //             font-size: 4.5rem;
  //             margin-top: 4rem;
  //             margin-bottom: 2rem;
  //           }

  //           @include size500 {
  //             font-size: 2.5rem;
  //             margin-top: 2rem;
  //             margin-bottom: 1rem;
  //           }
  //         }
  //         .para {
  //           color: #fff;
  //           width: 75%;
  //           font-size: 2rem;
  //           font-weight: 300;
  //           line-height: 150%;
  //           @include size1800 {
  //             font-size: 1.9rem;
  //             width: 45rem;
  //           }

  //           @include size1700 {
  //             width: 80%;
  //             font-size: 1.8rem;
  //           }
  //           @include size500 {
  //             width: 100%;
  //             font-size: 1.8rem;
  //           }
  //         }
  //       }
  //       .part1-container_right {
  //         width: 40%;
  //         height: 100%;
  //         display: flex;
  //         justify-content: flex-end;
  //         align-items: center;

  //         border-left: 1px solid #ccc;

  //         @include size500 {
  //           width: 100%;
  //           border: none;
  //           justify-content: center;
  //         }

  //         button {
  //           border: none;
  //           border-radius: 1rem;
  //           outline: none;
  //           padding: 2rem 4rem;
  //           background-color: #28de99;
  //           color: #fff;
  //           font-size: 3rem;
  //           font-weight: 600;
  //           font-family: "League Spartan";
  //           @include size500 {
  //             padding: 0.2rem 0.4rem;
  //             font-size: 2rem;
  //             margin-top: 1rem;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .part2 {
  //     // background-color: #000;
  //     width: 100%;
  //     // height: 30rem;
  //     padding-bottom: 10rem;
  //     margin-top: -5rem;
  //     color: #fff;
  //     .part2_top {
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: flex-start;
  //       @include size500 {
  //         flex-wrap: wrap;
  //       }
  //       .card {
  //         min-width: 15rem;
  //         line-height: 250%;
  //         font-size: 1.5rem;
  //         .heading {
  //           font-size: 2rem;
  //           font-weight: 600;
  //           margin-bottom: 3rem;
  //           // color: #f00;
  //         }
  //         &.Contact {
  //           padding-left: 2%;
  //           padding-right: 4%;
  //           line-height: 150%;
  //           font-size: 1.5rem;
  //           .heading {
  //             font-size: 2rem;
  //             font-weight: 600;
  //             margin-bottom: 0rem;
  //             width: 15rem;
  //             img {
  //               width: 100%;
  //               height: auto;
  //             }
  //             // color: #f00;
  //           }
  //           .contactNo {
  //             margin-top: 2rem;
  //           }
  //         }
  //       }
  //     }

  //     .part2_bottom {
  //       display: flex;
  //       width: 100%;
  //       justify-content: space-between;
  //       font-size: 1.5rem;
  //       margin-top: 2rem;
  //       @include size500 {
  //         flex-direction: column;
  //         & > div {
  //           margin-bottom: 1rem;
  //         }
  //       }
  //       .part2_bottom-left {
  //         display: flex;
  //         div {
  //           margin-right: 4rem;
  //           @include size1800 {
  //             margin-right: 3rem;
  //           }
  //           @include size1700 {
  //             margin-right: 2rem;
  //           }
  //           @include size1700 {
  //             margin-right: 2rem;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
