.backdrop2 {
  /* opacity: 0.7; */
  background-color: rgba(84, 166, 249, 0.762);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  /* width: 100%; */
}
