@import "sizes";
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.modal {
  width: 80rem;
  height: 40rem;
  background-color: #fff;
  z-index: 100000000;
  position: relative;

  @include size1000 {
    width: 80%;
    height: min-content;
  }

  .section1 {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -100%);
    img {
      width: 3rem;
      height: 3rem;
    }
  }
  .section2 {
    display: flex;
    @include size1000 {
      flex-direction: column;
    }
    .section2_left {
      width: 50%;
      margin-left: 3rem;
      margin-top: 3rem;
      @include size1000 {
        width: 100%;
        margin-left: 2rem;
      }

      .section2_left_p1 {
        font-size: 4rem;
        font-weight: 800;
        font-style: "Roboto";
        line-height: 6rem;
        @include size1000 {
          line-height: 4rem;
          font-size: 3rem;
        }
      }
      .section2_left_container2 {
        margin-top: 3rem;
        .section2_left_p2 {
          font-size: 2rem;
          line-height: 2.5rem;
          margin-right: 2.5rem;

          @include size500 {
            font-size: 1.5rem;
            line-height: 2rem;
          }
        }
      }
      .section2_left_container3 {
        margin-top: 8rem;
        @include size1000 {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
        button {
          padding: 1rem 5rem;
          font-size: 2rem;
          border: 3px solid #06ceff;
          color: #011521;
          background-color: #fff;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        }

        .buttons {
          text-align: center;
          @include size500 {
            margin-left: -2rem;
            margin-right: 2rem;
          }
        }

        .btn-hover {
          width: 200px;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          cursor: pointer;
          margin: 20px;
          height: 55px;
          text-align: center;
          border: none;
          background-size: 300% 100%;

          border-radius: 50px;
          transition: all 0.3s ease-in-out;
          @include size500 {
            width: 180px;
            margin: 15px;
            font-size: 15px;
          }
        }

        .btn-hover:hover {
          background-position: 100% 0;
          transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
          transform: scale(1.05);
        }

        .btn-hover:focus {
          outline: none;
        }
        .btn-hover.color-9 {
          background-image: linear-gradient(
            to right,
            #25aae1,
            #4481eb,
            #04befe,
            #3f86ed
          );
          box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
        }
      }
    }
    .section2_right {
      width: 50%;
      height: 40rem;

      // background-color: #fff;
      background-color: #06ceff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-grow: 4;

      @include size1000 {
        width: 100%;
        flex-direction: row;
        height: min-content;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include size1000 {
          // width: 36rem;
          height: 30rem;
        }
      }
    }
  }
}
