@import "sizes";
@import "utilitiesMixins";
@import "fontSize";

.home {
  position: relative;
  background: #f9f9f9;

  .section1 {
    width: 100%;
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-column-gap: 24px;
    padding-top: 8rem;

    @include size1400 {
      padding-top: 7rem;
    }
    @include size1300 {
      padding-top: 6rem;
    }
    @include size1200 {
      padding-top: 5rem;
      grid-template-columns: 5fr 3fr;
    }
    @include size1100 {
      padding-top: 4rem;
    }
    @include size1000 {
      display: flex;
      flex-direction: column;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // margin-top: 17rem;
      @include size600 {
        padding-top: 2rem;
      }

      .section1-title {
        color: var(--valleyblue-2, #0e1952);
        @include font_size(h05);
        font-family: Outfit;
        font-weight: 600;
        line-height: 120%;

        // @include size1200 {
        //   font-size: calc(7vw - 11px);
        // }
        // @include size600 {
        //   font-size: 4rem;
        // }
      }
      .section1-para {
        padding-top: 3rem;

        color: var(--rlhgray, #7a7a7a);
        @include font_size(b01);
        font-family: Outfit;
        line-height: 120%;
      }
      .section1-button {
        padding-top: 5rem;
        @include size600 {
          padding-top: 4rem;
        }
        .realButton {
          @include button-styles();
        }
      }
    }
    .right-content {
      width: 100%;
      @include size600 {
        // padding-top: 3rem;
        // margin-left: 3rem;
      }
      .internalContainer {
        width: 100%;
        height: fit-content;
        position: relative;
        @include size1000 {
          // width: 50vw;
          // width: min(600px, 80vw);
          width: 80vw;
          margin-inline: auto;
          display: flex;
          justify-content: center;
        }

        .femaledoc {
          width: 115%;
          height: fit-content;
          animation: fade 1500ms ease-in-out normal forwards;
          // animation: name duration timing-function delay  direction fill-mode;
          @include size1200 {
            width: 104%;
          }
          @include size1000 {
            width: 100%;
          }
          > img {
            width: 100%;
          }
        }
        .object-Container {
          display: inline-block;
          animation: show-right 1000ms ease-in-out 200ms normal forwards;
          // position: relative;

          border-radius: 10px;
          background: var(--valleywhite, #fff);
          box-shadow: 2px 2px 10px 0px rgba(219, 219, 219, 0.25),
            -2px -2px 10px 0px rgba(218, 218, 218, 0.25);

          &.object1 {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            width: 49%;
            height: 17.5%;
            bottom: 39%;
            left: 53%;
            // background-color: #fff;
            @include size1200 {
              right: 2%;
            }

            container-type: size;

            @container (min-height: 10px) {
              .title,
              .object-content {
                height: 25%;
              }
            }

            .title {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 500;
              color: var(--valleyblue-2, #0e1952);
              container-type: size;

              .rating {
                position: relative;
                height: 100%;
                padding-left: clamp(1rem, 1.3vw, 2rem);
                display: flex;
                align-items: center;
              }
              @container (min-width: 0px) {
                .rating {
                  font-size: 90cqh;
                  // background-color: #f00;
                }

                .smiley {
                  height: 100cqh;
                }
              }
            }
            .object-content {
              width: 100%;
              display: flex;
              justify-content: center;

              container-type: normal;

              @container (min-width: 0px) {
                // background-color: #f00;
                font-size: 8.5cqw;
              }
            }
          }

          &.object2 {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            // background-color: #f00;
            width: 53%;
            height: 17.5%;
            bottom: 7%;
            left: 6%;
            container-type: size;

            @container (min-height: 10px) {
              .title,
              .object-content {
                height: 25%;
              }
            }

            .title {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 500;
              color: var(--valleyblue-2, #0e1952);
              container-type: size;

              .rating {
                position: relative;
                height: 100%;
                padding-left: clamp(1rem, 1.3vw, 2rem);
                display: flex;
                align-items: center;
              }
              @container (min-width: 0px) {
                .rating {
                  font-size: 90cqh;
                  // background-color: #f00;
                }

                .love {
                  height: 100cqh;
                }
              }
            }
            .object-content {
              width: 100%;
              display: flex;
              justify-content: center;

              container-type: normal;

              @container (min-width: 0px) {
                // background-color: #f00;
                font-size: 8.5cqw;
              }
            }
          }
        }
      }
    }
  }
  .section2 {
    width: 100%;
    padding: 10rem 0;
    .image-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      grid-gap: 2.4rem;
      @include size600 {
        grid-template: repeat(3, 1fr) / 1fr;
        width: 80%;
        margin-inline: auto;
      }
    }
  }
  .section3 {
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-column-gap: 2.4rem;
    padding-bottom: 5rem;
    @include size1000 {
      display: flex;
      flex-direction: column-reverse;
    }
    @include size600 {
      padding-bottom: 2rem;
    }
    .internal-container {
      width: 100%;
      height: auto;
      aspect-ratio: 476/561;
      @include size1000 {
        width: 60%;
        margin-inline: auto;
        margin-top: 2rem;
      }
      @include size600 {
        width: 80%;
      }
      // background-color: #ff0;
      .images-Drop-Container {
        width: 100%;
        height: 100%;
        position: relative;

        .doc1 {
          width: 93.2%;
          height: auto;
          aspect-ratio: 443/413;
          top: 0;
          right: 0;
          position: absolute;
          transform-origin: left center;
          overflow: hidden;
          &[data-aos="doc1-animation"] {
            width: 0;
            transition-property: width;

            &.aos-animate {
              // transform: scaleX(1);
              width: 93.2%;

              // @include size800 {
              //   width: 7rem;
              // }
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
        .doc2 {
          width: 61.34%;
          height: auto;
          aspect-ratio: 292/267;
          position: absolute;
          bottom: 0;
          left: 0;
          transform-origin: left center;
          overflow: hidden;
          &[data-aos="doc2-animation"] {
            width: 0;
            transition-property: width;

            &.aos-animate {
              // transform: scaleX(1);
              width: 61.34%;

              // @include size800 {
              //   width: 7rem;
              // }
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .drop {
          width: 17.39%;
          height: auto;
          aspect-ratio: 83/88;
          position: absolute;
          right: 15.31%;
          bottom: 13.72%;
          transform-origin: left center;
          overflow: hidden;
          &[data-aos="drop-animation"] {
            width: 0;
            transition-property: width;

            &.aos-animate {
              // transform: scaleX(1);
              width: 17.39%;

              // @include size800 {
              //   width: 7rem;
              // }
            }
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding: 10rem 0;

      .heart-container {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        height: 6rem;
        img {
          display: block;
          height: 120%;
          width: auto;
          object-fit: cover;
          @include size600 {
            height: 80%;
          }
        }
      }

      .section3-Heading {
        color: var(--valleyblue-2, #0e1952);
        @include font_size(h05);
        width: 50rem;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 4rem;
        @include size1000 {
          width: max-content;
        }
        @include size700 {
          width: 50rem;
        }
        @include size400 {
          width: 30rem;
        }
      }
      .section3-para {
        color: var(--rlhgray, #7a7a7a);
        @include font_size(b01);
        line-height: 120%;
      }
    }
  }

  .section4 {
    width: 100%;
    padding: 5rem 0;
    .section4-maincontainer {
      width: 100%;
      display: grid;
      grid-template-columns: minmax(100px, 7fr) minmax(min(450px, 41vw), 5fr);
      grid-column-gap: 6rem;
      position: relative;
      @include size1000 {
        display: flex;
        flex-direction: column;
      }
      .section4-designElement1 {
        // margin-top: 5rem;
        @include size900 {
          margin-top: 2rem;
        }
        @include size600 {
          padding-bottom: 3rem;
        }

        .section4-heading {
          color: var(--valleyblue-2, #0e1952);
          @include font_size(h05);
          font-weight: 600;
          margin-bottom: 4rem;
          line-height: 120%;
          @include size1000 {
            margin-bottom: 2rem;
          }
        }
        .section4-para {
          padding-right: 10%;
          color: var(--rlhgray, #7a7a7a);
          @include font_size(b01);
          line-height: 120%;
        }
      }
    }
    .section4-designElement2 {
      width: 100%;
      align-self: center;

      .internal-container {
        width: 75%;
        height: auto;
        aspect-ratio: 303/293;
        margin-left: 3%;
        // height: 100%;
        position: relative;

        //   position: relative;
        .doc3-image {
          width: 100%;
          height: fit-content;
          .doc3 {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
        // .service-img {
        //   width: 2rem;
        //   height: auto;
        //   object-fit: cover;
        // }
        .service {
          display: inline-flex;
          position: absolute;
          padding: 1rem;
          align-items: center;
          gap: 0.6rem;
          border-radius: 8px;
          background: var(--valleywhite, #fff);
          box-shadow: 2px 2px 4px 0px rgba(203, 203, 203, 0.25),
            -2px -2px 4px 0px rgba(207, 207, 207, 0.25);
          @include size1200 {
            padding: 0.8vw;
          }
          // @include size600 {
          //   padding: 1rem;
          // }
          .service-content {
            color: var(--rlhblack, #222);
            font-size: 1.8rem;
            width: max-content;
            // @include size1200 {
            //   font-size: calc(1.7vw - 1px);
            // }
            // @include size600 {
            //   font-size: 1.2rem;
            // }
          }

          &.service1 {
            top: 13%;
            right: 70%;
          }
          &.service2 {
            left: 70%;
            top: 10%;
          }
          &.service3 {
            top: 37%;
            right: 77%;
          }
          &.service4 {
            left: 77%;
            top: 34%;
          }
          &.service5 {
            top: 61%;
            right: 84%;
          }
          &.service6 {
            left: 84%;
            top: 58%;
          }
        }
        .beat {
          position: absolute;
          bottom: -10%;
          left: -10%;
          transform-origin: left center;
          overflow: hidden;
          &[data-aos="beat-animation"] {
            width: 0;
            transition-property: width;

            &.aos-animate {
              width: 10.4rem;

              @include size800 {
                width: 7rem;
              }
            }
          }

          // animation: beat-show-right 1000ms ease-in-out 200ms normal forwards;
          img {
            width: 10.4rem;
            @include size800 {
              width: 7rem;
            }
          }
          // @include size1000 {
          //   bottom: 0;
          // }
        }
        container-type: size;
        @container (width < 600px) {
          .service {
            padding: 0.9rem;
            gap: 0.3rem;
            border-radius: 5px;

            .service-content {
              font-size: 1.7rem;
            }
          }
          .service-img {
            width: 2.5rem;
          }
        }
        @container (width < 500px) {
          .service {
            padding: 0.9rem;
            gap: 0.3rem;
            border-radius: 6px;

            .service-content {
              font-size: 1.6rem;
            }
          }
          .service-img {
            width: 2.5rem;
          }
        }
        @container (width < 400px) {
          .service {
            padding: 0.9rem;
            gap: 0.3rem;
            border-radius: 5px;

            .service-content {
              font-size: 1.5rem;
            }
          }
          .service-img {
            width: 2.5rem;
          }
        }
        @container (width < 300px) {
          .service {
            padding: 0.7rem;
            gap: 0.2rem;
            border-radius: 4px;

            .service-content {
              font-size: 1.3rem;
            }
          }
          .service-img {
            width: 2rem;
          }
        }
        @container (width < 200px) {
          .service {
            padding: 0.2rem;
            border-radius: 2px;
            .service-content {
              font-size: 1.2rem;
            }
          }
          .service-img {
            width: 1.3rem;
          }
        }
      }
      @include size1000 {
        width: 60%;
        margin-inline: auto;
        padding-top: 4rem;
        .internal-container {
          width: 100%;
          @include size400 {
            margin-left: 0%;
          }
        }
      }
    }
  }

  .section5 {
    width: 100%;
  }

  .section6 {
    .Bgsection5 {
      width: inherit;
    }
    // background-color: #fff;
    // background-color: #0099ff;

    // position: relative;
    // top: -1rem;
    // mix-blend-mode: multiply;

    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4.2rem;

    .section6-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #0099ff;
      position: relative;
      top: -1rem;
      .mainTitle {
        @include font_size(h05);
        font-weight: 600;
        color: #fff;

        @include size500 {
          width: 100%;
          font-size: 3.3rem;
          line-height: 120%;
          text-align: center;
        }
      }
      .desc {
        // width: 57rem;
        color: #fff;
        padding-top: 4rem;
        @include font_size(b01);
        line-height: 200%;
        text-align: center;
        width: 80%;
        // margin: 0 20rem;
        // margin-top:2rem;
        // @include size1600 {
        //   font-size: 1.3rem;
        //   padding-top: 3.5rem;
        //   width: 42rem;
        // }
        // @include size500 {
        //   width: 100%;
        //   padding-top: 1.5rem;
        //   font-size: 2rem;
        //   line-height: 120%;
        // }
      }

      .cardContainer {
        padding-top: 8rem;
        display: grid;
        grid-template-columns: repeat(4, minmax(220px, 3fr));
        grid-column-gap: 2rem;
        width: 80%;
        padding-bottom: 2rem;
        @include size1100 {
          overflow-x: scroll;
        }

        .cardElement {
          position: relative;
          // width: 25rem;
          // height: 35rem;
          background-color: #fff;
          padding-top: 0rem;
          padding: 0 1.5rem;
          border-radius: 1rem;
          overflow: hidden;
          // container-type: normal;
          // @container (min-height:0px) {
          //   .cardTitle {
          //     height: 10%;
          //   }
          //   .cardContent {
          //     height: 10%;
          //   }
          // }

          .cardIcon {
            border-radius: 1rem;
            background-color: #fff;
            z-index: 20;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 2rem;

            img {
              width: 90%;
              // height: 90%;
            }
          }

          .cardTitle {
            padding-top: 1rem;
            color: var(--rlhblack, #222);
            @include font_size(h01);
            font-weight: 500;
            text-align: center;
            line-height: 120%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .cardContent {
            padding-top: 1rem;
            color: var(--rlhgray, #7a7a7a);
            text-align: center;
            @include font_size(b01);
            line-height: 120%;
            text-align: center;
            font-family: Outfit;
            padding-bottom: 2rem;
          }
        }
      }
    }
  }

  .section7 {
    width: 100%;

    position: relative;
    display: grid;
    grid-template-columns: 5fr 7fr;
    // padding: 2rem 0;
    grid-column-gap: 1rem;
    @include size1000 {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 0rem;
      padding-top: 3rem;
    }

    .serviceimage-container {
      width: 100%;
      height: auto;
      position: relative;
      aspect-ratio: 476/712;
      @include size1000 {
        width: 500px;
        margin-inline: auto;
        margin-block: -3rem;
      }
      @include size600 {
        width: 400px;
      }
      @include size500 {
        width: 350px;
      }
      @include size400 {
        width: 300px;
      }

      .service1 {
        position: absolute;
        top: 13.48%;
        left: 3.99%;
        z-index: 1;
        width: 60.08%;
        aspect-ratio: 286.85/273.08;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .service2 {
        position: absolute;
        width: 66%;
        bottom: 13.45%;
        right: 3.9%;
        z-index: 1;
        width: 60.09%;
        aspect-ratio: 286.04/273.89;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .servicebg {
        position: absolute;
        bottom: 22.66%;
        left: 10.24%;
        width: 81.87%;
        aspect-ratio: 389.74/368.69;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .section7-content {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .section7-heading {
        color: var(--valleyblue-2, #0e1952);
        @include font_size(h04);
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 4rem;
        font-family: outfit;
      }
      .section7-para {
        color: var(--valleygray, #7a7a7a);
        @include font_size(b01);
        font-weight: 400;
        line-height: 120%;
        font-family: outfit;
      }
      .curve1 {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        height: 10rem;
        width: 100%;
        img {
          display: block;
          height: 120%;
          width: auto;
          object-fit: cover;
          @include size600 {
            height: 80%;
          }
        }
      }
    }
  }

  .section8 {
    width: 100%;
    // position: relative;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 6rem 0;
    @include size900 {
      padding-bottom: 14rem;
      padding-top: 0rem;
    }
    @include size600 {
      padding-bottom: 7rem;
      padding-top: 2rem;
    }

    .section8-heading {
      color: var(--valleyblue-2, #0e1952);
      @include font_size(h04);
      font-weight: 600;
      // margin-left: 4rem;
      margin-bottom: 4rem;
      line-height: 120%;
    }

    .testimonial-image-container {
      width: 39%;
      margin-inline: auto;
      padding-top: 5rem;
      @include size900 {
        padding-top: 10rem;
      }
      @include size700 {
        padding-top: 15rem;
        padding-bottom: 5rem;
      }
      @include size600 {
        width: 50%;
        // padding-bottom: 8rem;
      }
      @include size500 {
        padding-top: 18rem;
        padding-bottom: 8rem;
      }
      .testimonial-internal-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        .testimonial-doc {
          position: relative;
          width: 100%;

          .testdoc {
            width: 100%;
            // object-fit: cover;
            z-index: 10;
          }
          .test-obj {
            display: flex;
            padding: 1.5rem;
            width: 38rem;
            // aspect-ratio: 19.3;
            // width: calc(25.36vw);
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            border-radius: 12px;
            background: var(--valleywhite, #fff);
            position: absolute;
            @include size1200 {
              width: calc(32vw - 4px);
            }
            @include size900 {
              // aspect-ratio: 1.75;
              gap: 10px;
            }
            @include size600 {
              width: calc(33vw);
            }
            @include size500 {
              width: calc(45vw);
            }
            .test-obj-title {
              display: flex;
              justify-content: space-around;
              gap: 2rem;
              @include size900 {
                gap: 1.3rem;
              }
              .test-obj-icon {
                width: auto;
                @include size1300 {
                  width: calc(5.5vw - 4px);
                }
                @include size900 {
                  width: calc(6.1vw);
                }
                @include size500 {
                  width: 43px;
                  display: flex;
                  align-items: center;
                }
                .testimg {
                  width: 100%;
                }
              }
              .test-obj-heading-container {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .test-obj-heading {
                  @include font_size(h01);
                  font-style: normal;
                  font-weight: 500;
                  line-height: 120%;
                  font-family: Outfit;
                  color: var(--valleyblue-2, #0e1952);
                  padding-bottom: 1rem;
                  @include size900 {
                    padding-bottom: 0.5rem;
                  }
                }
                .test-obj-para {
                  @include font_size(b02);
                  font-weight: 400;
                  line-height: 120%;
                  font-family: Outfit;
                  color: var(--valleygray, #7a7a7a);
                }
              }
            }
            .test-obj-content {
              @include font_size(b02);
              font-weight: 400;
              line-height: 120%;
              font-family: Outfit;
              color: var(--valleygray, #7a7a7a);
            }

            &.test-obj1 {
              bottom: 70%;
              right: 96%;
              @include size600 {
                bottom: 83%;
                right: 80%;
              }
              @include size500 {
                bottom: 98%;
                right: 55%;
              }
            }
            &.test-obj2 {
              bottom: 70%;
              left: 82%;
              @include size500 {
                bottom: 93%;
                left: 58%;
              }
            }
            &.test-obj3 {
              top: 65%;
              right: 94%;
              @include size600 {
                right: 80%;
              }
              @include size500 {
                top: 98%;
                right: 55%;
                z-index: 5;
              }
            }
            &.test-obj4 {
              top: 66%;
              left: 82%;

              @include size500 {
                top: 98%;
                left: 58%;
              }

              // @include size700 {
              //   top: 92%;
              //   left: 96%;
              // }
            }
          }
        }
      }
    }
  }

  .section9 {
    width: 100%;
    padding: 10rem 0;
    @include size900 {
      padding-top: 0rem;
    }
    @include size600 {
      padding-top: 6rem;
    }

    .section9-heading {
      color: var(--valleyblue-2, #0e1952);
      font-family: Outfit;
      font-weight: 600;
      line-height: 120%;
      @include font_size(h04);
      margin-bottom: 4rem;
    }
    .blog-Main-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, minmax(260px, 4fr));
      grid-column-gap: 2rem;
      @include size800 {
        overflow-x: scroll;
        padding-bottom: 2rem;
      }

      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // @include size900 {
      //   width: 85.8rem;
      // }
      .blog-container {
        // width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .blog-image {
          width: 100%;
          > img {
            width: 100%;
          }
        }

        .blog-content {
          width: 30rem;

          padding: 2rem;
          border-radius: 12px;
          background: var(--valleywhite, #fff);
          @include size900 {
            width: 25rem;
          }
          .blog-title {
            display: flex;
            gap: 1rem;
            padding-bottom: 1rem;
            .date {
              @include font_size(b02);
              color: var(--valleygray, #7a7a7a);
              font-family: Outfit;
              font-weight: 400;
              line-height: 120%;
              display: flex;
              align-items: center;
            }
          }
          .para {
            @include font_size(b01);
            color: var(--valleyblue-2, #0e1952);
            font-family: Outfit;
            font-weight: 400;
            line-height: 120%;
            padding-bottom: 1rem;
          }
          .read-link {
            a.read-more {
              @include font_size(b02);
              color: var(--valleyblue-1, #09f);
              font-family: Outfit;
              font-weight: 500;
              line-height: 120%;
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }
}

.hideVisibility {
  visibility: hidden;
  @include size500 {
    display: none;
  }
}

@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes show-right {
  from {
    width: 20%;
    opacity: 0;
  }
}

// @keyframes beat-show-right {
//   from {
//     width: 0;
//   }
// }
