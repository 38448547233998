@import "sizes";
@import "utilitiesMixins";
@import "fontSize";
.inputTextFieldContainer {
  width: 40rem;
  margin: 1rem 1rem 0;
  padding-bottom: 1rem;
  @include size1000 {
    width: 90%;
    margin: 1rem auto 0;
  }

  .inputTextField {
    position: relative;
    display: inline-block;
    width: 100%;
    font-weight: 500;
    color: #444;

    .label {
      // margin-bottom: 0.5rem;
      // font-size: 1.6rem;
      color: var(--valleyblack, #222);
      @include font_size(b01);
      font-family: Outfit;
      font-weight: 400;
      line-height: 120%;
      padding-bottom: 1rem;
    }
  }
}
.inputTextField > input {
  @include font_size(b01);
  padding-left: 10px;
  display: block;
  height: 5rem;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--valleygray, #7a7a7a);
  opacity: 0.5;
}

.inputTextField > input:focus {
  outline: none;
}

/* LABEL ======================================= */

/* BOTTOM BARS ================================= */
/* .inputTextField > .bar {
  position: relative;
  display: block;
  width: 100%;
}

.inputTextField > .bar:before,
.inputTextField > .bar:after {
  content: "";
  height: 1.2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
} */

.inputTextField > .bar:before {
  left: 50%;
}

.inputTextField > .bar:after {
  right: 50%;
}

/* active state */
.inputTextField > input:focus ~ .bar:before,
.inputTextField > input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.inputTextField > .highlight {
  position: absolute;
  height: 70%;
  width: 100%;
  top: 10%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
  /* background-color: #ccc; */
}

.inputTextField.invalid > .highlight {
  background-color: rgba(237, 0, 0, 0.2);
}

.inputTextField.invalid + .error_element {
  color: #f00;
  margin-top: 0.4rem;
  font-size: 1.1rem;
  /* background-color: rgb(228, 163, 163); */
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  50% {
    background: #5264ae;
  }
  100% {
    height: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  50% {
    background: #5264ae;
  }
  100% {
    height: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  50% {
    background: #5264ae;
  }
  100% {
    height: 0;
    background: transparent;
  }
}
