@import "sizes";

.doctor {
  position: relative;

  .topDesignElement1 {
    position: absolute;
    right: 0;
    top: -8rem;
    width: 45rem;
    height: 45rem;
    z-index: 20;
    background-color: #0d6ae0;
    // overflow: hidden;
    border-radius: 50%;
    // transform: translate(100%, -100%);
    transform: translate(50%, -50%);

    @include size500 {
      width: 25rem;
      height: 25rem;
    }
  }
  .topDesignElement2 {
    position: absolute;
    right: 0;
    top: -8rem;
    width: calc(100% - 77rem);
    height: 78rem;
    z-index: 10;
    // overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: start;
    // background-image: url("../images/common/MainView.png");
    // background-repeat: no-repeat;

    @include size1000 {
      z-index: -1;
    }

    .topDesignElement2-container {
      position: relative;

      width: 100%;
      height: 100%;
      // background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
      .container2 {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        background-image: linear-gradient(
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.5)
        );
        .topPart {
          background-color: #0e63dc;
          height: 70%;
          @include size500 {
            background-color: rgba(14, 99, 220, 0.2);
          }
        }
        .bottomPart {
          height: 30%;
          background-color: #fff;
          @include size500 {
            background-color: rgba(14, 99, 220, 0.2);
          }
        }
      }

      .topDesignElement3 {
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 100%;
        height: min-content;
        border: none;
        img {
          position: relative;
          width: 100%;
          height: auto;
          left: 50%;
          transform: translate(-50%, 60%);
          border: none;
          @include size1000 {
            transform: translate(-50%, 70%);
            opacity: 0.2;
          }
          @include size500 {
            transform: translate(-50%, 50%);
            opacity: 0.2;
          }
        }
      }
    }

    @include size1500 {
      width: calc(100% - 72rem);
    }
    @include size1300 {
      width: calc(100% - 68rem);
    }
    @include size1200 {
      width: calc(100% - 64rem);
    }
    @include size1000 {
      width: 100%;
      height: 60rem;
    }
  }
  .section1 {
    height: 70rem;
    padding-top: 8rem;

    @include size1400 {
      padding-top: 7rem;
    }
    @include size1300 {
      padding-top: 6rem;
    }
    @include size1200 {
      padding-top: 5rem;
    }
    @include size1100 {
      padding-top: 4rem;
    }
    @include size1000 {
      padding-top: 0rem;
      height: 60rem;
    }
    @include size500 {
      height: 52rem;
    }
    .logo {
      width: 34rem;
      img {
        width: 100%;
        height: auto;
        @include size500 {
          width: 70%;
          padding-top: 1.5rem;
        }
      }

      // transform: translate(0, -100%);
    }

    .section1-title {
      position: relative;
      color: #0e1952;
      width: 58rem;
      font-size: calc(3.526rem + 1.316vw);
      // font-size: 5.5rem;

      line-height: 7rem;
      font-weight: 800;
      // font-family: "Montserrat";
      // background-color: #ccc;

      @include size1700 {
        line-height: 6.7rem;
        width: 45rem;
      }
      @include size1600 {
        line-height: 6.7rem;
        width: 46rem;
      }
      @include size1500 {
        line-height: 6.7rem;
        width: 48rem;
      }
      @include size1400 {
        line-height: 6.4rem;
        width: 50rem;
      }
      @include size1300 {
        width: 51rem;
      }
      @include size1200 {
        width: 47rem;
        font-size: 3rem;
        line-height: 130%;
      }
      @include size500 {
        width: 27rem;
      }

      &:before {
        content: "";
        position: absolute;
        background-color: #4385ce;
        top: -1rem;
        left: 0;
        height: 0.5rem;
        width: 7rem;
        border-radius: 2rem;
        @include size1400 {
          width: 6rem;
        }
      }
    }
    .section1-para {
      color: #7f8c9b;
      width: 39%;
      padding-top: 5rem;
      font-size: 1.9rem;
      font-weight: 600;
      letter-spacing: 0.03rem;
      line-height: 150%;

      @include size1500 {
        font-size: 1.6rem;
        width: 50rem;
      }
      @include size1400 {
        font-size: 1.5rem;
        width: 45rem;
      }
      @include size1400 {
        font-size: 1.5rem;
        width: 45rem;
      }
      @include size500 {
        width: 100%;
        color: #666;
      }
    }
    .section1-button {
      .realButton {
        background-color: #0e63dc;
        color: #fff;
        outline: none;
        border: none;
        font-size: 1.9rem;
        font-family: "League Spartan";
        letter-spacing: 0.1rem;
        font-weight: 600;
        padding: 2rem 3rem;
        border-radius: 5rem;
        margin: 4rem 0 0 2rem;

        @include size1500 {
          font-size: 1.5rem;
          padding: 2rem 3rem;
          margin: 4rem 0 0 2rem;
        }
        @include size500 {
          margin: 4rem 0 0 0rem;
        }
      }
    }
  }
  width: 100%;
  margin-bottom: 15rem;
  .doctorCard {
    position: relative;
    width: 1175px;
    height: 408px;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5rem;
    @include size1200 {
      width: 940px;
      height: 400px;
    }
    @include size1000 {
      width: 705px;
      height: 390px;
    }
    @media screen and (max-width: 710px) {
      width: calc(100% - 50px);
      height: initial;
    }
    @include size500 {
      width: 95%;

      height: initial;
      flex-direction: column;
      align-items: center;
      background-image: linear-gradient(132deg, #0e1952, #0a91eb 100%);
    }

    .photo {
      position: relative;
      width: 306px;
      height: 100%;
      background-color: rgb(255, 255, 255);
      z-index: 5;
      overflow: hidden;

      @include size1200 {
        width: 300px;
      }
      @include size1000 {
        width: 225px;
        height: 300px;
        position: absolute;
        top: 30px;
        right: 30px;
      }
      @media screen and (max-width: 710px) {
        width: 30%;
        height: auto;
        aspect-ratio: 100/125;
      }
      @include size500 {
        position: relative;
        width: 80%;
        aspect-ratio: 100/120;
        top: 0;
        right: 0;
        margin-top: 2rem;
        margin: 6%;
      }
      img {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        // opacity: 0.5;
        object-fit: cover;
      }
      .nameContainer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;

        z-index: 10;

        @include size1000 {
          font-size: 1.5rem;
        }
        @include size700 {
          font-size: calc(0.65rem + 1vw);
        }
        @include size500 {
          font-size: 2rem;
        }

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          z-index: -1;
          margin-bottom: -3%;
          background: linear-gradient(
            0deg,
            rgba(66, 62, 62, 0.8) 0%,
            // rgba(235, 0, 0, 0.8) 0%,
            rgba(66, 62, 62, 0.7) 20%,
            transparent 75%
          );
          transform-origin: left bottom;
          transform: scaleY(3);

          // background: #000;
        }
      }
    }
    .doctorDesc {
      position: relative;
      width: 869px;
      height: 390px;
      background-image: linear-gradient(132deg, #0e1952, #0a91eb 100%);
      color: #fff;
      padding-top: 60px;
      padding-left: 60px;

      @include size1200 {
        width: 640px;
        height: 385px;
      }
      @include size1000 {
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 30px 30px 100px 30px;
      }
      @include size500 {
        width: 100%;
        background-image: none;
        padding: initial;
      }

      .topEncapsulator {
        @include size500 {
          width: 88%;
          margin: 1rem auto;
        }
        .nameContainer {
          font-size: 4rem;

          @media screen and (max-width: 710px) {
            font-size: 2.5rem;
          }
        }

        .ratings {
          padding-top: 2px;
          padding-bottom: 30px;
        }
        .specialitiesBox {
          // background-color: #ccc;
          line-height: 120%;
          margin-bottom: 3rem;
          .heading {
            font-size: 1.7rem;
            font-weight: 600;
            letter-spacing: 0.1em;
            padding-bottom: 25px;
          }
          .desc {
            font-size: 1.8rem;
          }
        }

        .contentContainer {
          margin-right: 5%;
          font-size: 1.6rem;
          line-height: 120%;
          @include size1000 {
            margin-right: 26rem;
            font-size: 1.4rem;
            margin-top: -1.5rem;
          }
          @media screen and (max-width: 710px) {
            margin-right: calc(4rem + 30%);
          }
          @include size500 {
            margin-right: initial;
            line-height: 130%;
            letter-spacing: 0.05em;
          }

          .desc {
            margin-bottom: 1rem;
          }
        }
      }
      .bottomContent {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 90px;
        // background-color: #ff0;
        background: rgba($color: #000000, $alpha: 0.3);
        display: flex;
        align-items: center;

        @include size1200 {
          height: 80px;
        }
        @media screen and (max-width: 710px) {
          flex-direction: column;
          align-items: start;
          justify-content: space-evenly;
          padding-left: 30px;
        }
        @include size500 {
          position: relative;
        }
        .content1 {
          position: relative;
          padding-left: 60px;
          font-size: 2rem;
          @media screen and (max-width: 710px) {
            padding-left: initial;
          }
          @include size1200 {
            font-size: 1.6rem;
          }
          @include size500 {
            font-size: 1.7rem;
          }

          &::after {
            content: "|";
            position: relative;
            display: inline-block;
            margin: 0 20px;
            transform: scaleY(1.75);
            @media screen and (max-width: 1200px) {
              margin: 0 5px;
            }
            @media screen and (max-width: 710px) {
              content: "";
            }
          }
        }
        .content2 {
          font-size: 2rem;
          font-weight: 600;
          @include size500 {
            font-size: 1.7rem;
          }
          .content3 {
            font-size: 2rem;
            font-weight: 600;
            color: #fff;
            @include size500 {
              font-size: 1.7rem;
            }
          }
          &::after {
            content: "|";
            position: relative;
            display: inline-block;
            margin: 0 20px;
            transform: scaleY(1.75);
            @media screen and (max-width: 1200px) {
              margin: 0 5px;
            }
            @media screen and (max-width: 710px) {
              content: "";
            }
          }
        }
        .content5 {
          font-size: 2rem;
          font-weight: 600;
          @include size1200 {
            font-size: 1.5rem;
          }
          @include size500 {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}

[data-aos="custom-img-animate"] {
  opacity: 0.5;
  transition-property: opacity;
  &.aos-animate {
    opacity: 1;
  }
}

[data-aos="topDesignAnimate"] {
  // opacity: 0;
  // transition-property: opacity;
  // &.aos-animate {
  //   opacity: 1;
  // }
  // transform: translate(100%, -100%);
  transform: translate(150%, -150%);
  transition-property: transform;
  &.aos-animate {
    transform: translate(50%, -50%);
  }
}
