@import "sizes";

.nav {
  width: 100%;
  z-index: 400;
  background: #f9f9f9;
  .nav_container {
    display: flex;
    background: #f9f9f9;
    padding: 1rem 20rem;
    height: 8rem;
    align-items: center;

    @include size1500 {
      padding: 1rem 15rem;
    }

    @include size1200 {
      display: flex;
      background: #f9f9f9;
      padding: 1rem 12rem;
      height: 8rem;
      align-items: center;
    }

    @include size1100 {
      padding: 1rem 5.5rem;
    }

    @include size1000 {
      padding: 1rem 4.5rem;
    }

    .nav_container_left {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 100%;

      // width: 100%;
      z-index: 100000;
      padding-right: 4rem;

      @include size1000 {
        margin-left: -2.5rem;
      }

      img {
        padding: 0rem 2rem 0rem 0;
        padding-right: 2rem;
        /* background-color: #ddd; */
        height: 100%;
      }

      .brand_name {
        font-size: 2.5rem;
        /* height: min-content; */
        /* height: 100%; */
      }
    }

    .nav_container_right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      margin-right: -2.5rem;

      .elements {
        padding: 2.7rem 2.5rem;
        font-size: 1.7rem;
        font-weight: 500;
        color: #000000;
        mix-blend-mode: white;
        text-decoration: none;
        width: max-content;
        /* text-align: center; */
        height: max-content;
        z-index: 110;

        @include size1300 {
          padding: 2.7rem 2rem;
          font-size: 1.6rem;
        }

        &.bg-gray {
          color: #22cd04;
        }

        &.pushLeft {
          margin-right: auto;
        }

        &.active_link {
          color: #09f;
          position: relative;
          font-weight: 500;
          z-index: 100;
        }
        &.bg-gray:hover,
        &.bg-gray.active_link {
          color: #ffffff;
        }
      }
    }
  }
  button {
    z-index: 1000;
    outline: none;
    border: none;

    // background-color: transparent;

    &:hover * {
      color: #3490e6;
    }
  }

  .mobileViewContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh);
    z-index: 300;
  }
  .hideMenuItems .elements {
    display: none;
  }
  .cover_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #fff;
    .elements {
      font-size: 2rem;
      padding: 3rem 4rem;
      color: #000;
      text-decoration: none;
      width: 100%;
      text-align: center;

      // &:hover,
      // &:active {
      //   background-color: #000;
      // }

      &.active_link {
        color: #3490e6;
        position: relative;
        z-index: 10;
      }
    }
  }
  &.elements:focus {
    outline: none;
  }
}
