@import "sizes";
@import "utilitiesMixins";
@import "fontSize";
.career {
  position: relative;
  background: #f9f9f9;
  // .topDesignElement1 {
  //   position: absolute;
  //   right: 0;
  //   top: -8rem;
  //   width: 45rem;
  //   height: 45rem;
  //   z-index: 20;
  //   background-color: #0d6ae0;
  //   // overflow: hidden;
  //   border-radius: 50%;
  //   // transform: translate(100%, -100%);
  //   transform: translate(50%, -50%);

  //   @include size500 {
  //     width: 25rem;
  //     height: 25rem;
  //   }
  // }
  // .topDesignElement2 {
  //   position: absolute;
  //   right: 0;
  //   top: -8rem;
  //   width: calc(100% - 77rem);
  //   height: 78rem;
  //   z-index: 10;
  //   // overflow: hidden;
  //   display: flex;
  //   align-items: end;
  //   justify-content: start;
  //   // background-image: url("../images/common/MainView.png");
  //   // background-repeat: no-repeat;

  //   @include size1000 {
  //     z-index: -1;
  //   }

  //   .topDesignElement2-container {
  //     position: relative;

  //     width: 100%;
  //     height: 100%;
  //     // background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
  //     .container2 {
  //       width: 100%;
  //       height: 100%;
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       display: flex;
  //       flex-direction: column;
  //       background-image: linear-gradient(
  //         rgba(255, 255, 255, 0.5),
  //         rgba(255, 255, 255, 0.5)
  //       );
  //       .topPart {
  //         background-color: #000000;
  //         height: 70%;
  //         @include size500 {
  //           background-color: rgba(14, 99, 220, 0.2);
  //         }
  //       }
  //       .bottomPart {
  //         height: 30%;
  //         background-color: #fff;
  //         @include size500 {
  //           background-color: rgba(14, 99, 220, 0.2);
  //         }
  //       }
  //     }

  //     .topDesignElement3 {
  //       position: absolute;
  //       bottom: 50%;
  //       left: 0;
  //       width: 100%;
  //       height: min-content;
  //       border: none;
  //       img {
  //         position: relative;
  //         width: 100%;
  //         height: auto;
  //         left: 50%;
  //         transform: translate(-50%, 60%);
  //         border: none;
  //         @include size1000 {
  //           transform: translate(-50%, 70%);
  //           opacity: 0.2;
  //         }
  //         @include size500 {
  //           transform: translate(-50%, 50%);
  //           opacity: 0.2;
  //         }
  //       }
  //     }
  //   }

  //   @include size1500 {
  //     width: calc(100% - 72rem);
  //   }
  //   @include size1300 {
  //     width: calc(100% - 68rem);
  //   }
  //   @include size1200 {
  //     width: calc(100% - 64rem);
  //   }
  //   @include size1000 {
  //     width: 100%;
  //     height: 60rem;
  //   }
  // }
  .section1 {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-column-gap: 2rem;
    padding: 8rem 0;

    @include size1400 {
      padding-top: 7rem;
    }
    @include size1300 {
      padding-top: 6rem;
    }
    @include size1200 {
      padding-top: 5rem;
    }
    @include size1100 {
      padding-top: 4rem;
    }
    @include size1000 {
      display: flex;
      flex-direction: column;
    }
    @include size500 {
    }

    .career-content-container {
      display: flex;
      justify-content: center;
      // align-items: center;
      flex-direction: column;
      .section1-title {
        @include font_size(h05);
        color: var(--valleyblue-2, #0e1952);
        font-family: Outfit;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 4rem;
        width: 50rem;
        @include size500 {
          width: 100%;
        }
      }
      .section1-para {
        @include font_size(b01);
        color: var(--valleygray, #7a7a7a);
        font-family: Outfit;
        font-weight: 400;
        line-height: 120%;
        // margin-bottom: 2rem;
      }
      .section1-button {
        padding-top: 4rem;
        @include size600 {
          padding-top: 4rem;
        }
        .realButton {
          @include button-styles();
        }
      }
    }

    .careerDoc-container {
      width: 100%;
      .internal-careerDoc {
        width: 100%;
        position: relative;
        @include size1000 {
          width: 60%;
          margin: 5rem auto;
        }
        @include size600 {
          width: 85%;
        }
        .careerdoctor {
          width: 100%;
          animation: fade 1500ms ease-in-out normal forwards;
          > img {
            width: 100%;
          }
        }
        .Cobject {
          border-radius: 10px;
          background: var(--valleywhite, #fff);
          box-shadow: 2px 2px 4px 0px rgba(195, 195, 195, 0.25),
            -2px -2px 4px 0px rgba(208, 208, 208, 0.25);
          position: absolute;
          animation: show-right 1000ms ease-in-out 200ms normal forwards;

          container-type: size;
          @container (min-height: 10px) {
            .Cobject1-container {
              height: 70%;
            }
          }
          .Cobject1-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            container-type: size;

            .object-title {
              color: var(--valleyblack, #222);

              font-family: Outfit;
              font-weight: 400;
            }
            @container (min-width: 0px) {
              .object-title {
                font-size: 50cqh;
              }

              .ticker,
              .team {
                height: 90cqh;
                .icon {
                  height: 100%;
                }
              }
            }
          }
          &.Cobject1 {
            width: 65%;
            height: 10%;
            display: flex;

            justify-content: center;
            align-items: center;
            bottom: 46%;
            // right: 36%;
            left: 0%;

            // container-type: size;
            // @container (min-height: 10px) {
            //   .Cobject1-container {
            //     height: 70%;
            //   }
            // }
            // .Cobject1-container {
            //   width: 100%;
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   gap: 0.5rem;
            //   container-type: size;

            //   .object-title {
            //     color: var(--valleyblack, #222);

            //     font-family: Outfit;
            //     font-weight: 400;
            //   }
            //   @container (min-width: 0px) {
            //     .object-title {
            //       font-size: 50cqh;
            //     }

            //     .tick {
            //       height: 90cqh;
            //       .icon {
            //         height: 100%;
            //       }
            //     }
            //   }
            // }
          }

          &.Cobject2 {
            width: 61%;
            height: 10%;
            display: flex;

            justify-content: center;
            align-items: center;
            bottom: 24.5%;
            // right: 0%;
            left: 39%;

            // container-type: size;
            // @container (min-height: 10px) {
            //   .object1-container {
            //     height: 70%;
            //   }
            // }
            // .object1-container {
            //   width: 100%;
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   gap: 0.5rem;
            //   container-type: size;

            //   .object-title {
            //     color: var(--valleyblack, #222);

            //     font-family: Outfit;
            //     font-weight: 400;
            //   }
            //   @container (min-width: 0px) {
            //     .object-title {
            //       font-size: 50cqh;
            //     }

            //     .tick {
            //       height: 90cqh;
            //       .icon {
            //         height: 100%;
            //       }
            //     }
            //   }
            // }
          }
        }
      }
    }
  }
  .section-2 {
    .section2-title {
      color: var(--valleyblue-2, #0e1952);
      text-align: center;
      @include font_size(h04);
      font-family: Outfit;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8rem;
    }
    .section-2_container {
      width: 100%;
    }
    .section-2_container--description {
      margin-bottom: 15rem;
    }
  }
}
@keyframes slide {
  from {
    scale: 0;
    opacity: 0;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}
