@import "sizes";
.inputFileFieldContainer {
  width: 30rem;
  margin: 1rem 1rem 0;
  @include size400 {
    width: 90%;
    margin: 1rem auto 0;
  }
  margin: 1rem 1rem 0;
  .inputFileField {
    position: relative;
    display: inline-block;
    width: 100%;
    font-weight: 500;
    color: #444;

    .title {
      margin-bottom: 0.5rem;
      font-size: 1.6rem;
    }
    input {
      display: none;
    }
    .uploadBox {
      width: 100%;
      height: 3.5rem;
      line-height: 3.5rem;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 400;
      border: 1px solid transparent;
      border-radius: 5px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: rgb(4, 129, 231);
      color: #eee;
    }
    .error_element {
      color: #f00;
      margin-top: 0.4rem;
      font-size: 1.1rem;
      font-weight: 400;
    }
  }
}
