@import "sizes";

.modalApplicationForm {
  padding: 4rem 3rem 4rem;
  background-color: #fff;
  z-index: 100000000;
  position: relative;
  .job {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    color: rgb(4, 129, 231);
    font-weight: 600;
  }
  .disclaimer {
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    margin: 1rem;
    color: rgb(4, 129, 231);
  }
  @include size400 {
    width: 90%;
  }
  .submit_container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 2rem;
    .button {
      font-size: 1.2rem;
      padding: 0.8rem 1.5rem;
      border-radius: 1rem;
      // color: rgb(2, 0, 9);
      border-style: solid;
      // border-color: rgb(0, 58, 106);
      background-color: #fff;
      font-weight: 600;
    }
  }
}
