@import "sizes";
@import "utilitiesMixins";
@import "fontSize";
.section-title {
  color: var(--valleyblue-1, #09f);
  @include font_size(h02);
  font-weight: 500;
  line-height: 120%;
  font-family: outfit;
  margin-bottom: 4rem;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 5.5rem;
    height: 0.25rem;
    background-color: var(--valleyblue-1, #09f);
  }
}
