@import "sizes";
@import "utilitiesMixins";
@import "fontSize";
$lineHeight: (
  "default": 4rem,
  "s1000": 3rem,
  "s500": 2.3rem,
);
.FAQ_QA {
  // background-color: #ccc;
  margin: 4rem 0;
  padding: 3rem;
  background: var(--valleywhite, #fff);
  .question_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include size600 {
      align-items: start;
    }
    .question {
      // font-size: 2rem;

      // line-height: 120%;
      width: 95%;
      padding-bottom: 2rem;
      // line-height: map-get($map: $lineHeight, $key: "default");
      // @include size1000 {
      //   line-height: map-get($map: $lineHeight, $key: "s1000");
      // }
      // @include size900 {
      //   width: 90%;
      // }
      // @include size500 {
      //   line-height: map-get($map: $lineHeight, $key: "s500");
      //   font-size: 1.5rem;
      // }
      .question_row1 {
        color: var(--valleyblack, #222);
        @include font_size(h01);
        font-weight: 500;
        @include size500 {
          width: 23rem;
        }
      }
      // .question_row2 {
      //   // color: var(--valleygray, #7a7a7a);
      //   // @include font_size(h01);
      //   // font-weight: 400;
      //   // line-height: 120%;
      //   padding-top: 2rem;
      //   // // font-size: 80%;
      //   display: flex;
      //   gap: 3rem;
      //   @include size500 {
      //     gap: 1rem;
      //   }
      //   .dep {
      //     display: flex;
      //     align-items: center;
      //     gap: 1.5rem;
      //     @include size600 {
      //       gap: 1rem;
      //     }
      //     .workIon {
      //       @include size500 {
      //         width: 3rem;
      //       }
      //       @include size400 {
      //         width: 2rem;
      //       }
      //       .work {
      //         @include size500 {
      //           width: 100%;
      //         }
      //       }
      //     }
      //     .department {
      //       color: var(--valleygray, #7a7a7a);
      //       @include font_size(h01);
      //       font-weight: 400;
      //     }
      //   }
      //   .places {
      //     // position: relative;
      //     // margin-left: 2rem;
      //     display: flex;
      //     gap: 1.5rem;
      //     align-items: center;
      //     @include size600 {
      //       gap: 1rem;
      //     }
      //     .locIon {
      //       @include size500 {
      //         width: 3rem;
      //       }
      //       @include size400 {
      //         width: 2rem;
      //       }
      //       .loc {
      //         @include size500 {
      //           width: 100%;
      //         }
      //       }
      //     }
      //     .place {
      //       color: var(--valleygray, #7a7a7a);
      //       @include font_size(h01);
      //       font-weight: 400;
      //     }
      //   }
      // }
    }
    .showHideImg {
      width: map-get($map: $lineHeight, $key: "default");
      height: map-get($map: $lineHeight, $key: "default");

      @include size1000 {
        width: map-get($map: $lineHeight, $key: "s1000");
        height: map-get($map: $lineHeight, $key: "s1000");
      }
      @include size500 {
        width: map-get($map: $lineHeight, $key: "s500");
        height: map-get($map: $lineHeight, $key: "s500");
      }
      display: flex;
      border-radius: 50%;
      border-color: #faf5f5;
      border-style: solid;
      border-width: 1px;

      background-color: transparent;
      cursor: pointer;
      .imgDiv {
        // padding: 2rem;
        // width: 100%;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          // @include size1000 {
          //   width: 1rem;
          //   height: 1rem;
          // }
        }
      }
    }
  }
  .question_row2 {
    // color: var(--valleygray, #7a7a7a);
    // @include font_size(h01);
    // font-weight: 400;
    // line-height: 120%;
    padding-bottom: 2rem;
    // // font-size: 80%;
    display: flex;
    gap: 3rem;
    @include size500 {
      gap: 1rem;
    }
    .dep {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      @include size600 {
        gap: 1rem;
      }
      .workIon {
        width: 2rem;
        @include size900 {
          width: 1.6rem;
        }
        @include size430 {
          width: 1.4rem;
        }
        .work {
          width: 100%;
        }
      }
      .department {
        color: var(--valleygray, #7a7a7a);
        @include font_size(b01);
        font-weight: 400;
      }
    }
    .places {
      // position: relative;
      // margin-left: 2rem;
      display: flex;
      gap: 1.5rem;
      align-items: center;
      @include size600 {
        gap: 1rem;
      }
      .locIon {
        width: 2rem;
        @include size900 {
          width: 1.6rem;
        }
        @include size430 {
          width: 1.4rem;
        }
        .loc {
          width: 100%;
        }
      }
      .place {
        color: var(--valleygray, #7a7a7a);
        @include font_size(b01);
        font-weight: 400;
      }
    }
  }
  .answer {
    font-size: 1.8rem;
    line-height: 120%;

    // margin-left: 2vw;
    overflow: hidden;
    font-weight: 300;
    max-width: 95%;
    &.hide {
      // height: 0;
      // display: none;
      // visibility: hidden;
      animation: slideUp 1s ease forwards;
    }
    margin-top: 1.5rem;
    height: max-content;

    @include size500 {
      margin-top: 1.7rem;
      line-height: 1.7rem;
      font-size: 1.5rem;
    }
    .jobContainer {
      .title {
        color: var(--valleyblack, #222);
        @include font_size(h01);
        font-weight: 500;
        line-height: 120%;
      }
      .shortDesc {
        color: var(--valleygray, #7a7a7a);
        text-align: justify;
        @include font_size(b01);
        font-weight: 400;
        line-height: 120%;
        padding: 3.2rem 0;
      }
      > .detailDesc {
        .detailDescElement {
          margin-bottom: 1rem;
          .detailDescString {
            display: flex;
            gap: 1rem;
            padding-bottom: 2rem;
            .detailElement {
              color: var(--valleygray, #7a7a7a);
              @include font_size(b01);
              font-weight: 400;
              line-height: 120%;
            }
          }
          .titleElement {
            color: var(--valleyblack, #222);
            @include font_size(b01);
            font-weight: 400;
            line-height: 120%;
          }
          > .listContainer {
            margin-left: 5rem;
            line-height: 140%;
            color: var(--valleygray, #7a7a7a);
            @include font_size(b01);
            font-weight: 400;
            padding: 2rem 0;
            li {
              padding: 0.5rem 0;
            }
          }
        }
      }
    }

    animation: super 1s ease;
  }
  // animation: slideUp 1s ease;
  .apply_now {
    // margin-top: 1rem;
    // padding: 1rem 2rem;
    // letter-spacing: 0.1em;
    // font-size: 1.2rem;
    // font-weight: 600;
    // background-color: rgb(0, 120, 232);
    // border-radius: 1rem;
    // color: #fff;
    // border: none;
    background: var(--valleyblue-1, #09f);
    color: #fff;
    outline: none;
    border: none;
    // @include font_size(button);
    @include font_size(b01);
    padding: 1rem 2rem;
    border-radius: 0.8rem;
    text-decoration: none;
    letter-spacing: 1px;
    @include size600 {
      border-radius: 0.4rem;
      padding: 1.5rem 2.5rem;
    }
    // @include button-styles();
  }
}

// @keyframes super {
//   0% {
//     opacity: 0;
//     transform: translateX(-100px);
//     // height: 0;
//   }
//   100% {
//     opacity: 1;
//     transform: translateX(0%);
//     // height: max-content;
//   }
// }
@keyframes super {
  0% {
    height: 0;
    overflow: hidden;
  }
  100% {
    height: 50rem;
  }
}

@keyframes slideUp {
  0% {
    height: 500px;
  }
  100% {
    height: 0;
    // height: max-content;
  }
}
