@import "sizes";
.carousel {
  position: relative;
  width: 100%;

  .leftarrow {
    position: absolute;
    top: 50%;
    left: -8rem;
    transform: translateY(-50%);
    @include size1300 {
      left: -4rem;
    }
    @include size1200 {
      left: 0;
      transform: translate(-50%, -50%);
    }
    // @include size1100 {
    //   left: -3.5rem;
    // }
    // left: 2rem;
  }

  .rightarrow {
    position: absolute;
    top: 50%;
    right: -8rem;
    transform: translateY(-50%);
    @include size1300 {
      right: -4rem;
    }
    @include size1200 {
      right: 0;
      transform: translate(50%, -50%);
    }
    // @include size1100 {
    //   right: -3.5rem;
    // }
  }
  .rightarrow,
  .leftarrow {
    background: transparent;
    border: none;
    // @include size1000 {
    //   display: none;
    // }
  }
}

.slide-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 3rem;

  margin-top: 5rem;
  @include size1200 {
    // grid-template-columns: 1fr 1.5fr;
    padding-left: 0;
    width: 95%;
    margin: 0 auto;
  }
  @include size1000 {
    padding: 4rem 0;
  }
  // @include size1100 {
  //   grid-template-columns: 1fr 2fr;
  // }
  // @include size1000 {
  //   grid-template-columns: 1fr 3.5fr;
  // }
  // @include size900 {
  //   grid-template-columns: 1fr 4.25fr;
  // }
  // @include size800 {
  //   grid-template-columns: 1fr 6fr;
  // }

  @include size1000 {
    display: flex;
    flex-direction: column;
  }
}
.slide-image {
  width: 100%;
  @include size1000 {
    width: 50%;
  }
  @include size900 {
    width: 50%;
  }
  @include size600 {
    width: 80%;
  }
  // @include size600 {
  //   width: 100%;
  // }

  img {
    width: 100%;
  }
}

.slide-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .slide-title {
    padding-bottom: 3rem;
    @include size600 {
      // padding-bottom: 0rem;
    }
  }
  @include size1000 {
    padding-top: 2rem;
  }

  // align-items: center;
  .slide-content-heading {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 500;
    font-size: 43px;
    text-align: center;
    // line-height: 40px;
    color: #0e1952;
    // padding-bottom:3rem;
    @include size1000 {
      font-size: 2.8rem;
    }
  }

  .slide-content-para {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    // text-align: justify;
    color: #7a7a7a;
    padding-left: 4rem;
    @include size1500 {
      // font-size:19px;
    }
    @include size1200 {
    }
    @include size1000 {
      // padding-left: 6rem;
    }
    @include size600 {
      // padding: 1rem;
    }
  }
}
